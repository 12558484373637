import React, { FC, useEffect } from 'react';

import { useOrganizationAddonConfigsQuery } from '@hofy/api-admin';
import { allPaymentSchemasWithoutDeposit, useTrAddonType, useTrPaymentSchema } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import {
    CheckboxGroup,
    ErrorStatePlaceholder,
    FormDateInput,
    FormGridRow,
    FormModal,
    FormNumberInput,
    FormSection,
    FormSelect,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph4,
    SkeletonList,
    SubmitButton,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useEnrollDevicesIntoServicePackage } from '../../../store/contracts/useEnrollDevicesIntoServicePackage';
import { useOrganizationDetailsQuery } from '../../../store/organizations/useOrganizationDetailsQuery';

interface DeviceEnrollmentModalProps {
    organizationId: UUID;
    selectedItemsIds: number[];
    onClose(): void;
}

export const DeviceEnrollmentModal: FC<DeviceEnrollmentModalProps> = ({
    organizationId,
    selectedItemsIds,
    onClose,
}) => {
    // Translations
    const trPaymentSchema = useTrPaymentSchema();
    const trAddonType = useTrAddonType();

    // Queries
    const { data: organization, isLoading: isOrganizationLoading } =
        useOrganizationDetailsQuery(organizationId);

    const { data: addonsConfigs, isLoading: areAddonConfigsLoading } =
        useOrganizationAddonConfigsQuery(organizationId);

    // Form
    const {
        form,
        isLoading: isFormLoading,
        isError: isFormError,
    } = useEnrollDevicesIntoServicePackage(selectedItemsIds, onClose);

    const selectedServiceAddon = addonsConfigs.find(ac => ac.id === form.values.addonIds[0]);

    // Effects
    useEffect(() => {
        if (form.values.addonIds.length === 0) {
            return;
        }

        if (!selectedServiceAddon) {
            return;
        }

        form.setValues({
            duration: selectedServiceAddon.config.enrollmentDuration,
            paymentSchema: selectedServiceAddon.config.enrollmentPaymentSchema,
        });
    }, [form.values.addonIds]);

    if (isOrganizationLoading || areAddonConfigsLoading) {
        return <SkeletonList items={3} height={100} gap={10} />;
    }

    if (!organization || !addonsConfigs) {
        return <ErrorStatePlaceholder />;
    }

    return (
        <FormModal
            onSubmit={form.submit}
            onClose={onClose}
            isLoading={isFormLoading}
            isError={isFormError}
            width={600}
        >
            <ModalHeader title='Enrol devices into addon' />
            <ModalContent column gap={20}>
                <FormSection label='Available services'>
                    <FormGridRow columns={2}>
                        <CheckboxGroup
                            options={addonsConfigs.filter(
                                ac => ac.isEligibleForEnrollmentByAdmins && ac.validForOrganization.state,
                            )}
                            value={addonsConfigs.filter(ac => form.values.addonIds.includes(ac.id))}
                            onChange={selectedAddons =>
                                form.setValues({
                                    ...form.values,
                                    addonIds: selectedAddons.map(ac => ac.id),
                                })
                            }
                            toLabel={addon => `${addon.name} (${trAddonType(addon.type)})`}
                            toKey={addon => addon.id}
                        />
                    </FormGridRow>
                </FormSection>
                <FormSection label='Billing details'>
                    <FormGridRow columns={1}>
                        <FormDateInput label='Activation date' api={form.fields.activeOn} nullable />
                    </FormGridRow>
                    <FormGridRow columns={2}>
                        <FormNumberInput
                            label='Enrollment duration (months)'
                            api={form.fields.duration}
                            nullable
                        />
                        <FormSelect
                            label='Payment schema'
                            api={form.fields.paymentSchema}
                            nullable
                            options={allPaymentSchemasWithoutDeposit}
                            disabled={!!selectedServiceAddon?.config.enrollmentPaymentSchema}
                            toText={trPaymentSchema}
                        />
                    </FormGridRow>
                </FormSection>
                <FormSection label='Devices to enroll'>
                    <Paragraph4>
                        Information about the devices that will be enrolled into the addon. Which ones are
                        already enrolled, which ones are not eligible, and the duration/price of enrollment
                        otherwise
                    </Paragraph4>
                </FormSection>
            </ModalContent>
            <ModalFooter>
                <CancelButton label='Cancel' onClick={onClose} />
                <SubmitButton label='Enroll' />
            </ModalFooter>
        </FormModal>
    );
};

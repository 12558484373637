import React, { FC } from 'react';

import {
    AdminAssignmentFilters,
    AssignmentsPageTab,
    useAssignmentsByShipmentQuery,
    UserAssignmentDto,
} from '@hofy/api-admin';
import { ShipmentType } from '@hofy/api-shared';
import { ComponentLoader, InfiniteScroll } from '@hofy/common';
import { Country, Sort, UUID } from '@hofy/global';
import { Box, Placeholder, SvgIllustration } from '@hofy/ui';

import { SortOptions } from '../../../../store/assignments/useAdminAssignmentsSortings';
import {
    emptyUserAssignmentSelectionRecord,
    UserAssignmentSelectionRecord,
} from '../../../../store/assignments/useAssignmentsSelection';
import { UserShipments } from './UserShipments';

interface UsersShipmentsProps {
    tab: AssignmentsPageTab;
    filters: AdminAssignmentFilters;
    sortOptions: Sort<SortOptions>;
    selectedAssignments: Record<UUID, UserAssignmentSelectionRecord>;
    onToggleSelectedAssignments(a: UserAssignmentDto[]): void;
    onCreateShipment(userId: UUID, organizationId: UUID, country: Country, shipmentType: ShipmentType): void;
    onInPersonTransfer(userId: UUID, organizationId: UUID, country: Country): void;
}

export const UsersShipments: FC<UsersShipmentsProps> = ({
    tab,
    filters,
    sortOptions,
    selectedAssignments,
    onToggleSelectedAssignments,
    onCreateShipment,
    onInPersonTransfer,
}) => {
    const {
        userAssignments,
        fetchNextPage,
        userAssignmentsIsFetchingNextPage,
        userAssignmentsHasNextPage,
        userAssignmentsIsLoading,
    } = useAssignmentsByShipmentQuery(tab, filters, sortOptions);

    if (userAssignmentsIsLoading) {
        return (
            <Box flex='auto' relative>
                <ComponentLoader />
            </Box>
        );
    }

    if (userAssignments.length === 0) {
        return (
            <Placeholder
                illustration={SvgIllustration.Shipment}
                title='No assignments'
                message='No assignments for selected criteria'
            />
        );
    }

    return (
        <InfiniteScroll
            direction='column'
            gap={12}
            padding={12}
            overflow='auto'
            hasMore={userAssignmentsHasNextPage}
            isLoadingMore={userAssignmentsIsFetchingNextPage}
            loadMore={fetchNextPage}
        >
            {userAssignments.map(user => (
                <UserShipments
                    key={user.id}
                    user={user}
                    selected={selectedAssignments[user.id] || emptyUserAssignmentSelectionRecord}
                    onToggleSelected={onToggleSelectedAssignments}
                    onCreateShipment={onCreateShipment}
                    onInPersonTransfer={onInPersonTransfer}
                />
            ))}
        </InfiniteScroll>
    );
};

import React, { FC } from 'react';

import { RepairListFilters, RepairListSorting, useListRepairsQuery } from '@hofy/api-admin';
import { formatAdminName, formatVariant, UserRefDto, WarehouseRefDto } from '@hofy/api-shared';
import { ComponentLoader, InfiniteScroll } from '@hofy/common';
import { formatDate } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import {
    Box,
    Button,
    ErrorStatePlaceholder,
    Labeled,
    LabeledText,
    Paragraph3,
    Placeholder,
    SectionTitle2,
    SvgIllustration,
} from '@hofy/ui';

import { ProductItem } from '../../components/domain/products/ProductItem';
import { Link } from '../../components/routing/Link';
import { ItemLocationCell } from '../itemsPage/components/ItemLocationCell';
import { OrganizationLink } from '../organizationsPage/OrganizationDetailsLink';
import { UserDetailsLink } from '../usersPage/UserDetailsLink';
import { RepairAdminSelector } from './components/RepairAdminSelector';
import { RepairMenu } from './components/RepairMenu';
import { RepairStatusChip } from './components/RepairStatusChip';
import { useRepairsLinks } from './useRepairsLinks';

interface RepairsListProps {
    filters: RepairListFilters;
    sorting: RepairListSorting;
}

export const RepairsList: FC<RepairsListProps> = ({ filters, sorting }) => {
    const { getOpenRepairProgressLink, getOpenRepairDetailsLink, getOpenItemLink } = useRepairsLinks();

    const { repairs, isLoading, isError, isFetchingNextPage, hasNextPage, fetchNextPage } =
        useListRepairsQuery(filters, sorting);

    if (isLoading) {
        return (
            <Box flex='auto' relative>
                <ComponentLoader />
            </Box>
        );
    }

    if (isError) {
        return (
            <Box flex='auto'>
                <ErrorStatePlaceholder />
            </Box>
        );
    }

    if (repairs.length === 0) {
        return (
            <Box flex='auto' fullHeight centered>
                <Placeholder
                    illustration={SvgIllustration.AssetRepair}
                    title='No repairs'
                    message='No repairs for selected criteria'
                />
            </Box>
        );
    }

    return (
        <InfiniteScroll
            direction='column'
            gap={12}
            padding={12}
            overflow='auto'
            hasMore={!!hasNextPage}
            isLoadingMore={isFetchingNextPage}
            loadMore={fetchNextPage}
            bg={Color.BackgroundSubtleNeutral}
            flex={1}
        >
            {repairs.map(
                ({
                    idDeprecated,
                    status,
                    statusUpdatedOn,
                    item,
                    user,
                    organization,
                    warehouse,
                    assignedUser,
                    createdAt,
                    cancelledAt,
                }) => (
                    <Box
                        key={idDeprecated}
                        column
                        gap={10}
                        border
                        rounded
                        padding={10}
                        bg={Color.BackgroundDefault}
                    >
                        <Box row gap={8} alignItems='flex-end'>
                            <RepairItemTitle user={user} warehouse={warehouse} />
                            {organization && (
                                <Paragraph3>
                                    <OrganizationLink id={organization.id}>
                                        {organization.name}
                                    </OrganizationLink>
                                </Paragraph3>
                            )}
                        </Box>
                        <Box row gap={20} border rounded padding={10} bg={Color.BackgroundSubtleNeutral}>
                            <Box width={80}>
                                <Link
                                    to={getOpenRepairDetailsLink(idDeprecated)}
                                    color={Color.ContentPrimary}
                                >
                                    <LabeledText label='Repair' content={`#${idDeprecated}`} labelGap={0} />
                                </Link>
                            </Box>

                            <Box width={80}>
                                <Link to={getOpenItemLink(item.id)} color={Color.ContentPrimary}>
                                    <LabeledText label='Item' content={`#${item.id}`} labelGap={0} />
                                </Link>
                            </Box>

                            <Box flex={1}>
                                <ItemLocationCell location={item.location} />
                            </Box>

                            <Box flex={2}>
                                <ProductItem
                                    image={item.variant.image}
                                    label={item.product.name}
                                    subLabel={formatVariant(item.variant)}
                                />
                            </Box>

                            <Box row flex={2} justify='flex-end'>
                                <RepairStatusChip status={status} isCancelled={!!cancelledAt} />
                            </Box>

                            <RepairMenu
                                width={20}
                                id={idDeprecated}
                                status={status}
                                isCancelled={!!cancelledAt}
                            />
                        </Box>
                        <Box row>
                            <Labeled
                                flex={1}
                                labelGap={4}
                                label='Assigned to'
                                content={
                                    <Box row>
                                        <RepairAdminSelector repairId={idDeprecated} user={assignedUser} />
                                    </Box>
                                }
                            />
                            <LabeledText
                                flex={1}
                                label='Created on'
                                content={formatDate(createdAt)}
                                labelGap={0}
                            />
                            <LabeledText
                                flex={1}
                                label='Status changed on'
                                content={formatDate(statusUpdatedOn)}
                                labelGap={0}
                            />
                            <Button label='Track progress' to={getOpenRepairProgressLink(idDeprecated)} />
                        </Box>
                    </Box>
                ),
            )}
        </InfiniteScroll>
    );
};

interface RepairItemTitleProps {
    user: UserRefDto | null;
    warehouse: WarehouseRefDto | null;
}

const RepairItemTitle: FC<RepairItemTitleProps> = ({ user, warehouse }) => {
    if (user) {
        const userName = `#${user.id} ${formatAdminName(user)}`;
        return (
            <SectionTitle2>
                <UserDetailsLink id={user.id}>{userName}</UserDetailsLink>
            </SectionTitle2>
        );
    }

    if (warehouse) {
        return <SectionTitle2>{warehouse.name} </SectionTitle2>;
    }

    return null;
};

import { union } from 'lodash';

import { AssignmentStatus, UpdateOtdReason } from '@hofy/api-shared';
import { PageRequest, PageResponse, regionToCountries, Sort, stringifyUrl, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { AddLoanerPayload } from './types/AddLoanerPayload';
import { AdminAssignmentFilters } from './types/AdminAssignmentFilters';
import { AssignItemPayload } from './types/AssignItemPayload';
import { AssignmentConfigurationDto } from './types/AssignmentConfigurationDto';
import { AssignmentDetailsDto } from './types/AssignmentDetailsDto';
import { AssignmentsPageTab } from './types/AssignmentsPageTab';
import { AssignmentWithProductDto } from './types/AssignmentWithProductDto';
import { CheckInItemPayload } from './types/CheckInItemPayload';
import { CollectAssignmentPayload } from './types/CollectAssignmentPayload';
import { CreateAssignmentForItemPayload } from './types/CreateAssignmentForItemPayload';
import { EnableStoreAndReuseCollectionPayload } from './types/EnableStoreAndReuseCollectionPayload';
import { EnableStoreAndReuseDeliveryPayload } from './types/EnableStoreAndReuseDeliveryPayload';
import { InPersonTransferPayload } from './types/InPersonTransferPayload';
import { ReplaceAssignmentPayload } from './types/ReplaceAssignmentPayload';
import { ReportShipmentIssuePayload } from './types/ReportShipmentIssuePayload';
import { ScanAssignedItemPayload } from './types/ScanAssignedItemPayload';
import { StolenItemPayload } from './types/StolenItemPayload';
import { SubstituteProductPayload } from './types/SubstituteProductPayload';
import { UpdateAssignmentConfigurationPayload } from './types/UpdateAssignmentConfigurationPayload';
import { UpdateCollectionReasonPayload } from './types/UpdateCollectionReasonPayload';
import { UpdateOnHoldAssignmentPayload } from './types/UpdateOnHoldAssignmentPayload';
import { UpdateVariantPayload } from './types/UpdateVariantPayload';
import { UserAssignmentsByShipmentDto } from './types/UserAssignmentByShipmentDto';

interface AdminUserAssignmentsResponse {
    assignments: AssignmentWithProductDto[];
}

class AssignmentService {
    public listAssignmentsByShipment = async <S extends string>(
        type: AssignmentsPageTab,
        filters: AdminAssignmentFilters,
        sort: Sort<S>,
        page: PageRequest,
    ): Promise<PageResponse<UserAssignmentsByShipmentDto>> => {
        const orderTypeTabs = [AssignmentsPageTab.Delivery, AssignmentsPageTab.Collection];
        const regionCountries = filters.region.flatMap(regionToCountries);
        return restClient.getJson<PageResponse<UserAssignmentsByShipmentDto>>(
            stringifyUrl({
                url: `/api/admin/assignments/list-by-shipment`,
                query: {
                    deliveriesAtRisk: type === AssignmentsPageTab.AtRisk,
                    shipToday: type === AssignmentsPageTab.ShipToday,
                    escalated: type === AssignmentsPageTab.Escalated,
                    deferred: type === AssignmentsPageTab.Deferred,
                    page: page.page,
                    pageSize: page.pageSize,
                    allAssignments: filters.isPending === false,
                    orderType: orderTypeTabs.includes(type) ? type : undefined,
                    search: filters.search,
                    allShipments: filters.allShipments,
                    shipmentStatus: filters.shipmentStatus,
                    shipmentType: filters.shipmentType,
                    assignedUser: filters.assignedUsers,
                    containingLaptopOnly: filters.containingLaptopOnly,
                    configRequired: filters.configRequired,
                    newJoiners: filters.newJoiners,
                    futureScheduled: filters.futureScheduled,
                    scheduledOnly: filters.scheduledOnly,
                    country: union(filters.countries, regionCountries),
                    warehouse: filters.warehouses,
                    isLoaner: filters.isLoaner,
                    isStoreAndReuse: filters.isStoreAndReuse,
                    isDisposal: filters.isDisposal,
                    isPartner: filters.isPartner,
                    scanned: filters.scanned,
                    unscanned: filters.unscanned,
                    sortBy: sort.sortBy,
                    sortDirection: sort.sortDirection,
                    collectionSurveyStatus: filters.collectionSurveyStatus,
                    otdDate: filters.otdDate,
                    estimatedShipDateFrom: filters.shipDateRange?.from ?? null,
                    estimatedShipDateTo: filters.shipDateRange?.to ?? null,
                    deliveryDate: filters.deliveryDate,
                    ownership: filters.ownership,
                    isOnHold: filters.isOnHold,
                },
            }),
        );
    };

    public getUserAssignments = async (
        userId: UUID,
        status?: AssignmentStatus[],
    ): Promise<AssignmentWithProductDto[]> => {
        const response = await restClient.getJson<AdminUserAssignmentsResponse>(
            stringifyUrl({
                url: `/api/admin/assignments/users/${userId}`,
                query: {
                    status,
                },
            }),
        );
        return response.assignments;
    };

    public getAssignment = (id: number): Promise<AssignmentDetailsDto> => {
        return restClient.getJson<AssignmentDetailsDto>(`/api/admin/assignments/${id}`);
    };

    public getAssignmentConfiguration = (id: number): Promise<AssignmentConfigurationDto> => {
        return restClient.getJson<AssignmentConfigurationDto>(`/api/admin/assignments/${id}/configuration`);
    };

    public updateVariant = async (id: number, payload: UpdateVariantPayload): Promise<void> => {
        return restClient.put(`/api/admin/assignments/${id}`, payload);
    };

    public purchaseItem = async (assignmentID: number): Promise<void> => {
        return restClient.put(`/api/admin/assignments/${assignmentID}/purchase`);
    };
    public substituteProduct = async (
        assignmentId: number,
        payload: SubstituteProductPayload,
    ): Promise<void> => {
        return restClient.post(`/api/admin/assignments/${assignmentId}/substitute`, payload);
    };
    public addLoaner = async (assignmentId: number, payload: AddLoanerPayload): Promise<void> => {
        return restClient.post(`/api/admin/assignments/${assignmentId}/add-loaner`, payload);
    };

    public updateAssignmentConfiguration = async (
        assignmentId: number,
        payload: UpdateAssignmentConfigurationPayload,
    ): Promise<void> => {
        return restClient.put(`/api/admin/assignments/${assignmentId}/configuration`, payload);
    };

    public cancelSubstitution = async (id: number): Promise<void> => {
        return restClient.post(`/api/admin/assignments/${id}/cancel-substitution`);
    };

    public createAssignment = async (
        userId: UUID,
        payload: CreateAssignmentForItemPayload,
    ): Promise<void> => {
        return await restClient.post(`/api/admin/assignments/users/${userId}`, payload);
    };

    public updateCollectionReason = async (
        assignmentId: number,
        payload: UpdateCollectionReasonPayload,
    ): Promise<void> => {
        return restClient.put(`/api/admin/assignments/${assignmentId}/collect`, payload);
    };

    public replace = async (assignmentId: number, payload: ReplaceAssignmentPayload): Promise<void> => {
        return restClient.post(`/api/admin/assignments/${assignmentId}/replace`, payload);
    };

    public collectAssignment = async (
        assignmentId: number,
        payload: CollectAssignmentPayload,
    ): Promise<void> => {
        return restClient.post(`/api/admin/assignments/${assignmentId}/collect`, payload);
    };

    public checkInItem = async (assignmentId: number, payload: CheckInItemPayload): Promise<void> => {
        return restClient.put(`/api/admin/assignments/${assignmentId}/check-in`, payload);
    };

    public cancelAssignment = async (assignmentID: number): Promise<void> => {
        return restClient.post(`/api/admin/assignments/${assignmentID}/cancel`);
    };

    public cancelCollection = async (assignmentID: number): Promise<void> => {
        return restClient.post(`/api/admin/assignments/${assignmentID}/cancel-collection`);
    };

    public updateOtd = async (assignmentId: number, otd: string, reason: UpdateOtdReason): Promise<void> => {
        return restClient
            .post(`/api/admin/assignments/${assignmentId}/otd`, {
                otd,
                reason,
            })
            .then();
    };

    public assignItem = async (assignmentId: number, payload: AssignItemPayload): Promise<void> => {
        return restClient.put(`/api/admin/assignments/${assignmentId}/assign-item`, payload);
    };

    public scanAssignedItem = async (
        assignmentId: number,
        payload: ScanAssignedItemPayload,
    ): Promise<void> => {
        return restClient.put(`/api/admin/assignments/${assignmentId}/scan-item`, payload);
    };

    public unAssignItem = async (assignmentId: number): Promise<void> => {
        return restClient.delete(`/api/admin/assignments/${assignmentId}/assign-item`).then();
    };

    public revertScanItem = async (assignmentId: number): Promise<void> => {
        return restClient.post(`/api/admin/assignments/${assignmentId}/revert-scan-item`);
    };

    public reportShipmentIssue = async (
        assignmentId: number,
        payload: ReportShipmentIssuePayload,
    ): Promise<void> => {
        return restClient.put(`/api/admin/assignments/${assignmentId}/shipment-issue`, payload);
    };

    public stolenItem = async (assignmentId: number, payload: StolenItemPayload): Promise<void> => {
        return restClient.put(`/api/admin/assignments/${assignmentId}/stolen`, payload);
    };

    public disposeItem = async (assignmentId: number): Promise<void> => {
        return restClient.put(`/api/admin/assignments/${assignmentId}/dispose`);
    };

    public enableStoreAndReuseDelivery = async (
        assignmentId: number,
        payload: EnableStoreAndReuseDeliveryPayload,
    ): Promise<void> => {
        return restClient
            .post(`/api/admin/assignments/${assignmentId}/store-and-reuse-delivery`, payload)
            .then();
    };

    public revertCompletion = async (assignmentId: number): Promise<void> => {
        return restClient.post(`/api/admin/assignments/${assignmentId}/revert-collected`);
    };

    public cancelStoreAndReuseDelivery = async (assignmentId: number): Promise<void> => {
        return restClient.delete(`/api/admin/assignments/${assignmentId}/store-and-reuse-delivery`).then();
    };

    public enableStoreAndReuseCollection = async (
        assignmentId: number,
        payload: EnableStoreAndReuseCollectionPayload,
    ): Promise<void> => {
        return restClient
            .post(`/api/admin/assignments/${assignmentId}/store-and-reuse-collection`, payload)
            .then();
    };

    public cancelStoreAndReuseCollection = async (assignmentId: number): Promise<void> => {
        return restClient.delete(`/api/admin/assignments/${assignmentId}/store-and-reuse-collection`).then();
    };

    public convertLoanerToReplacement = async (assignmentId: number): Promise<void> => {
        return restClient.post(`/api/admin/assignments/${assignmentId}/convert-loaner-to-replacement`).then();
    };

    public updateOnHold = async (
        assignmentId: number,
        payload: UpdateOnHoldAssignmentPayload,
    ): Promise<void> => {
        return restClient.put(`/api/admin/assignments/${assignmentId}/on-hold`, payload);
    };

    public inPersonTransfer = async (payload: InPersonTransferPayload): Promise<void> => {
        return restClient.post(`/api/admin/assignments/in-person-transfer`, payload);
    };
}

export const assignmentService = new AssignmentService();

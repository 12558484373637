import { isEmpty } from 'lodash';
import React, { FC, ReactNode, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AuditableTable, useItPoliciesQuery, useTermsAndConditionsQuery } from '@hofy/api-admin';
import { OrganizationStatus, Permission, Role } from '@hofy/api-shared';
import { useSession, useSignInToOrganization } from '@hofy/auth';
import { ComponentLoader, MoreMenu, Page } from '@hofy/common';
import { stringifyUrl, UUID, zeroUuid } from '@hofy/global';
import { useGoBack } from '@hofy/hooks';
import { useStringQueryParam } from '@hofy/router';
import { Color } from '@hofy/theme';
import {
    ActionDropdown,
    Box,
    Button,
    DropdownListItem,
    Modals,
    PageHeader,
    SearchInput,
    SvgIcon,
    Tooltip,
} from '@hofy/ui';
import { PermissionWrapper } from '@hofy/ui-domain';

import { DangerousConfirmModal } from '../../components/design/modal/DangerousConfirmModal';
import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { useAuditLogMenuOption } from '../../store/auditLogs/useAuditMenuOption';
import { OrganizationTab, SearchableOrganizationTabs } from '../../store/organizations/types/OrganizationTab';
import { useDeleteOrganization } from '../../store/organizations/useDeleteOrganization';
import { useDisconnectOrganizationHRIS } from '../../store/organizations/useDisconnectOrganizationHRIS';
import { useOrganizationAccountingSync } from '../../store/organizations/useOrganizationAccountingSync';
import { useOrganizationDetailsQuery } from '../../store/organizations/useOrganizationDetailsQuery';
import { useSyncOrganizationToZendesk } from '../../store/organizations/useSyncOrganizationToZendesk';
import { AddonConfigurationsTab } from './addonsTab/AddonConfigurationsTab';
import { BillingEntitiesTab } from './billingEntitiesTab/BillingEntitiesTab';
import { AddTermAndConditionModal } from './components/AddTermAndConditionModal';
import { ApplyRetrospectivelyTermAndConditionModal } from './components/ApplyRetrospectivelyTermAndConditionModal';
import { ContractDefaultsUpdateModal } from './components/ContractDefaultsUpdateModal';
import { CreateItPolicyModal } from './components/CreateItPolicyModal';
import { CurrencyUpdateModal } from './components/CurrencyUpdateModal';
import { DataErasureUpdateModal } from './components/DataErasureUpdateModal';
import { DeelUpdateModal } from './components/DeelUpdateModal';
import { DetailsUpdateModal } from './components/DetailsUpdateModal';
import { DisconnectHRISConfirmationModal } from './components/DisconnectHRISConfirmationModal';
import { FeaturesUpdateModal } from './components/FeaturesUpdateModal';
import { FinancialSettingsUpdateModal } from './components/financialSettingsModal/FinancialSettingsUpdateModal';
import { GenerateOrganizationInvoicesModal } from './components/GenerateOrganizationInvoicesModal';
import { MdmUpdateModal } from './components/MdmUpdateModal';
import { StatusUpdateModal } from './components/StatusUpdateModal';
import { UsersUploadModal } from './components/UsersUploadModal';
import { ContractSettingsTab } from './contractSettingsTab/ContractSettingsTab';
import { DetailsTab } from './detailsTab/DetailsTab';
import { DeviceEnrollmentModal } from './devicesTab/DeviceEnrollmentModal';
import { OrganizationDevicesTab } from './devicesTab/OrganizationDevicesTab';
import { DiscountsTab } from './discountsTab/DiscountsTab';
import { OrganizationEmailsTab } from './emailsTab/OrganizationEmailsTab';
import { InvoiceGroupsTab } from './invoiceGroups/InvoiceGroupsTab';
import { OrganizationInvoicesTable } from './invoicesTab/OrganizationInvoicesTable';
import { OrganizationDetailsPageTabs } from './OrganizationDetailsPageTabs';
import { PaymentDiscountsTab } from './paymentDiscountsTab/PaymentDiscountsTab';
import { SubscriptionsTab } from './subscriptionsTab/SubscriptionsTab';
import { ItPoliciesTab } from './teamsTab/ItPoliciesTab';
import { TermsAndConditionsTab } from './termsAndConditionsTab/TermsAndConditionsTab';
import { UsersTab } from './usersTab/UsersTab';

enum ConfirmationModal {
    AddTeam,
    ApplyRetrospectively,
    DisconnectHRIS,
    DeleteOrganization,
    AddTermAndCondition,
    GenerateInvoicesModal,
    UpdateCurrency,
    DataErasureUpdate,
    UsersUpload,
    UpdateStatus,
}

enum ManageModal {
    Details,
    FinancialSettings,
    Features,
    Status,
    Mdm,
    Currency,
    DataErasure,
    Deel,
    ContractDefaults,
    AddItemsToServicePackage,
}

interface OrganizationDetailsPageProps {
    organizationId: UUID;
    organizationTab: OrganizationTab;
    onChangeTab(organizationTab: OrganizationTab): void;
    onOpenCreateUser(id: UUID): void;
    onOpenUser(id: UUID): void;
    onInvoiceClick(id: number): void;
    onItemClick(id: number): void;
    onOpenBillingEntity(billingEntityId: number): void;
    onUpdateContractSetting(organizationId: UUID, contractSettingId: UUID): void;
    onUpdateAddonConfiguration(addonId: UUID): void;
    onCreateSubscription(): void;
    onSubscriptionClick(subscriptionId: number): void;
    onOpenEmail(emailId: number): void;
    getCreateBillingEntityLink(): string;
}

export const OrganizationDetailsPage: FC<OrganizationDetailsPageProps> = ({
    organizationTab,
    organizationId,
    onChangeTab,
    onOpenCreateUser,
    onOpenUser,
    getCreateBillingEntityLink,
    onOpenBillingEntity,
    onUpdateContractSetting,
    onUpdateAddonConfiguration,
    onCreateSubscription,
    onSubscriptionClick,
    onInvoiceClick,
    onItemClick,
    onOpenEmail,
}) => {
    const history = useHistory();

    const { data: organization, isLoading } = useOrganizationDetailsQuery(organizationId);
    const { data: termsAndConditions, isLoading: isTermsAndConditionsLoading } = useTermsAndConditionsQuery();
    const { goBack } = useGoBack();
    const { hasPermission, hasRole } = useSession();
    const { signInToOrganization } = useSignInToOrganization();
    const { accountingSync } = useOrganizationAccountingSync(organizationId);
    const { syncToZendesk } = useSyncOrganizationToZendesk(organizationId);
    const { form: disconnectOrganizationForm } = useDisconnectOrganizationHRIS(organization?.id || zeroUuid);
    const { deleteOrganization } = useDeleteOrganization(organizationId, () =>
        goBack(AdminNavLink.Organizations),
    );
    const [confirmationModal, setConfirmationModal] = useState<ConfirmationModal>();
    const [manageModal, setManageModal] = useState<ManageModal>();
    const [search, setSearch] = useStringQueryParam('search', '');
    const [menuLink] = useAuditLogMenuOption(AuditableTable.Organization, undefined, organizationId);
    const { itPolicies, isItPoliciesLoading } = useItPoliciesQuery(organizationId || zeroUuid);

    const isTabSearchable = useMemo(
        () => SearchableOrganizationTabs.includes(organizationTab),
        [organizationTab],
    );
    const [selectedItemsIds, setSelectedItemsIds] = useState<number[]>([]);

    const moreMenu = (
        <MoreMenu
            items={[
                {
                    action: () => setConfirmationModal(ConfirmationModal.DisconnectHRIS),
                    label: 'Disconnect HRIS',
                    testKey: 'disconnect-hris',
                    visible: hasPermission(Permission.AdminOrganizationUpdate),
                },
                {
                    action: accountingSync,
                    label: 'Sync to Netsuite',
                    visible: hasPermission(Permission.AdminAccountingNonTransactionalSync),
                },
                {
                    action: syncToZendesk,
                    label: 'Sync to Zendesk',
                    visible: hasPermission(Permission.AdminOrganizationSyncToZendesk),
                },
                {
                    action: () => setConfirmationModal(ConfirmationModal.GenerateInvoicesModal),
                    label: 'Generate invoices',
                    visible: hasPermission(Permission.AdminInvoicesGenerate),
                },
                {
                    action: () => {
                        history.push(
                            stringifyUrl({
                                url: AdminNavLink.Contracts,
                                query: {
                                    organizations: organization?.id,
                                },
                            }),
                        );
                    },
                    color: Color.NonContextualBlueDefault,
                    label: 'View contracts',
                },
                menuLink,
                {
                    action: () => setConfirmationModal(ConfirmationModal.DeleteOrganization),
                    color: Color.FoundationNegative,
                    label: 'Delete organization',
                    testKey: 'delete-organization',
                    visible: hasPermission(Permission.AdminOrganizationDelete),
                },
            ]}
        />
    );

    if (isLoading || isTermsAndConditionsLoading || !organization) {
        return <ComponentLoader />;
    }

    const renderContent = () => {
        switch (organizationTab) {
            case OrganizationTab.Details:
                return <DetailsTab organization={organization} />;
            case OrganizationTab.Invoices:
                return (
                    <OrganizationInvoicesTable
                        onEntryClick={onInvoiceClick}
                        organizationId={organizationId}
                        search={search}
                    />
                );
            case OrganizationTab.ItPolicies:
                return (
                    <ItPoliciesTab
                        itPolicies={itPolicies}
                        isItPoliciesLoading={isItPoliciesLoading}
                        search={search}
                    />
                );
            case OrganizationTab.Users:
                return <UsersTab organizationId={organizationId} onOpenUser={onOpenUser} search={search} />;
            case OrganizationTab.BillingEntities:
                return (
                    <BillingEntitiesTab
                        search={search}
                        onOpenBillingEntity={onOpenBillingEntity}
                        billingEntities={organization.billingEntities}
                    />
                );
            case OrganizationTab.Devices:
                return (
                    <OrganizationDevicesTab
                        onEntryClick={onItemClick}
                        organizationId={organizationId}
                        search={search}
                        selectedItemsIds={selectedItemsIds}
                        setSelectedItemsIds={setSelectedItemsIds}
                    />
                );
            case OrganizationTab.Discounts:
                return <DiscountsTab organization={organization} />;
            case OrganizationTab.RentalEquipmentPaymentDiscounts:
                return <PaymentDiscountsTab organization={organization} />;
            case OrganizationTab.ContractSettings:
                return (
                    <ContractSettingsTab
                        onEditContractSetting={id => onUpdateContractSetting(organizationId, id)}
                        contractSettings={organization.defaultContractSettings}
                    />
                );
            case OrganizationTab.TermsAndConditions:
                return <TermsAndConditionsTab organizationId={organizationId} search={search} />;
            case OrganizationTab.Addons:
                return (
                    <AddonConfigurationsTab
                        organizationId={organizationId}
                        onUpdateAddonConfiguration={onUpdateAddonConfiguration}
                        search={search}
                    />
                );
            case OrganizationTab.InvoiceGroups:
                return <InvoiceGroupsTab organization={organization} />;
            case OrganizationTab.Emails:
                return <OrganizationEmailsTab organization={organization} onOpenEmail={onOpenEmail} />;
            case OrganizationTab.Subscriptions:
                return <SubscriptionsTab organizationId={organizationId} onClick={onSubscriptionClick} />;
        }
    };
    const { teamLimit, billingEntityLimit } = organization.platformTierConfig;
    const billingEntityLimitExceeded =
        !!billingEntityLimit && organization.billingEntities.length >= billingEntityLimit;

    const renderExceededBillingEntityLimitTooltip = (content: ReactNode) => {
        if (billingEntityLimitExceeded) {
            return (
                <Tooltip body='Organization reached the maximum number of billing entities on current plan.'>
                    {content}
                </Tooltip>
            );
        }
        return content;
    };

    return (
        <Page pageId='admin-organization-details'>
            <PageHeader
                title={organization.name}
                rightSlot={
                    <>
                        {organizationTab === OrganizationTab.Users && (
                            <>
                                <Button
                                    type='secondary'
                                    label='New user'
                                    onClick={() => onOpenCreateUser(organizationId)}
                                    disabled={!hasPermission(Permission.AdminUsersCreate)}
                                    leftIcon={SvgIcon.Add}
                                />
                                <Button
                                    type='secondary'
                                    label='Upload users'
                                    onClick={() => setConfirmationModal(ConfirmationModal.UsersUpload)}
                                    disabled={!hasPermission(Permission.AdminUsersCreate)}
                                    leftIcon={SvgIcon.Upload}
                                />
                            </>
                        )}
                        {organizationTab === OrganizationTab.ItPolicies &&
                            (!teamLimit || itPolicies.length < teamLimit) && (
                                <Button
                                    type='secondary'
                                    label='New team'
                                    onClick={() => setConfirmationModal(ConfirmationModal.AddTeam)}
                                    disabled={!hasPermission(Permission.AdminOrganizationUpdate)}
                                    leftIcon={SvgIcon.Add}
                                />
                            )}
                        {organizationTab === OrganizationTab.Details && (
                            <Button
                                type='secondary'
                                leftIcon={SvgIcon.User}
                                label='Sign in to organization'
                                onClick={() => signInToOrganization(organization.id)}
                                disabled={!hasPermission(Permission.AdminSignInToOrganization)}
                            />
                        )}
                        {organizationTab === OrganizationTab.BillingEntities &&
                            renderExceededBillingEntityLimitTooltip(
                                <Button
                                    type='secondary'
                                    label='Create billing entity'
                                    leftIcon={SvgIcon.Settings}
                                    to={getCreateBillingEntityLink()}
                                    disabled={
                                        !hasPermission(Permission.AdminOrganizationUpdateFinancialSettings) ||
                                        billingEntityLimitExceeded
                                    }
                                />,
                            )}
                        {organizationTab === OrganizationTab.TermsAndConditions && (
                            <>
                                <Button
                                    type='secondary'
                                    label='Add term and condition'
                                    onClick={() =>
                                        setConfirmationModal(ConfirmationModal.AddTermAndCondition)
                                    }
                                    disabled={!hasPermission(Permission.AdminOrganizationUpdate)}
                                    leftIcon={SvgIcon.Add}
                                />
                                {hasRole(Role.SuperAdmin) && (
                                    <Button
                                        type='secondary'
                                        label='Apply retrospectively'
                                        onClick={() =>
                                            setConfirmationModal(ConfirmationModal.ApplyRetrospectively)
                                        }
                                        disabled={!hasPermission(Permission.AdminOrganizationUpdate)}
                                    />
                                )}
                            </>
                        )}
                        {organizationTab === OrganizationTab.Subscriptions && (
                            <Button
                                type='secondary'
                                label='Create subscription'
                                onClick={onCreateSubscription}
                                disabled={!hasPermission(Permission.AdminOrganizationCreate)}
                                leftIcon={SvgIcon.Add}
                            />
                        )}
                        {organizationTab === OrganizationTab.Devices && (
                            <Button
                                type='secondary'
                                label='Enrol into service package'
                                onClick={() => setManageModal(ManageModal.AddItemsToServicePackage)}
                                disabled={
                                    !hasPermission(Permission.AdminItemsEnrolIntoServicePackage) ||
                                    selectedItemsIds.length === 0
                                }
                                leftIcon={SvgIcon.Add}
                            />
                        )}
                        {isTabSearchable && <SearchInput value={search} onChange={setSearch} />}

                        <ActionDropdown label='Manage'>
                            <Box gap={4} column>
                                <PermissionWrapper permission={Permission.AdminOrganizationUpdate} tooltip>
                                    <DropdownListItem
                                        label='Details'
                                        onClick={() => setManageModal(ManageModal.Details)}
                                    />
                                </PermissionWrapper>
                                <PermissionWrapper
                                    permission={Permission.AdminOrganizationUpdateFinancialSettings}
                                    tooltip
                                >
                                    <DropdownListItem
                                        label='Financial settings'
                                        onClick={() => setManageModal(ManageModal.FinancialSettings)}
                                    />
                                </PermissionWrapper>
                                <PermissionWrapper
                                    permission={Permission.AdminOrganizationUpdateFeatures}
                                    tooltip
                                >
                                    <DropdownListItem
                                        label='Features'
                                        onClick={() => setManageModal(ManageModal.Features)}
                                    />
                                </PermissionWrapper>
                                <PermissionWrapper
                                    permission={
                                        organization.status === OrganizationStatus.Trial
                                            ? Permission.AdminOrganizationUpdateTrialStatus
                                            : Permission.AdminOrganizationUpdateFinancialSettings
                                    }
                                    tooltip
                                >
                                    <DropdownListItem
                                        label='Status'
                                        onClick={() => setManageModal(ManageModal.Status)}
                                    />
                                </PermissionWrapper>
                                <PermissionWrapper permission={Permission.AdminOrganizationUpdateMdm} tooltip>
                                    <DropdownListItem
                                        label='MDM'
                                        onClick={() => setManageModal(ManageModal.Mdm)}
                                    />
                                </PermissionWrapper>
                                <PermissionWrapper
                                    permission={Permission.AdminOrganizationUpdateCurrency}
                                    tooltip
                                >
                                    <DropdownListItem
                                        label='Currency'
                                        onClick={() => setManageModal(ManageModal.Currency)}
                                    />
                                </PermissionWrapper>
                                <PermissionWrapper
                                    permission={Permission.AdminOrganizationUpdateDataErasureSettings}
                                    tooltip
                                >
                                    <DropdownListItem
                                        label='Data erasure'
                                        onClick={() => setManageModal(ManageModal.DataErasure)}
                                    />
                                </PermissionWrapper>
                                <PermissionWrapper permission={Permission.AdminOrganizationUpdate} tooltip>
                                    <DropdownListItem
                                        label='Deel'
                                        onClick={() => setManageModal(ManageModal.Deel)}
                                    />
                                </PermissionWrapper>
                                {!isEmpty(organization.contractExpirySettings) && (
                                    <PermissionWrapper
                                        permission={Permission.AdminOrganizationUpdateContractExpirySettings}
                                        tooltip
                                    >
                                        <DropdownListItem
                                            label='Contract defaults'
                                            onClick={() => setManageModal(ManageModal.ContractDefaults)}
                                        />
                                    </PermissionWrapper>
                                )}
                            </Box>
                        </ActionDropdown>

                        {moreMenu}
                    </>
                }
                tabsSlot={
                    <OrganizationDetailsPageTabs
                        tab={organizationTab}
                        onChange={onChangeTab}
                        unbundlingEnabled={organization.unbundlingEnabled}
                    />
                }
            />
            <Box flex='auto' relative>
                {renderContent()}
            </Box>

            <Modals>
                {confirmationModal === ConfirmationModal.AddTeam && (
                    <CreateItPolicyModal
                        onClose={() => setConfirmationModal(undefined)}
                        organization={organization}
                    />
                )}
                {confirmationModal === ConfirmationModal.AddTermAndCondition && (
                    <AddTermAndConditionModal
                        onClose={() => setConfirmationModal(undefined)}
                        organizationId={organizationId}
                        termsAndConditions={termsAndConditions}
                    />
                )}
                {confirmationModal === ConfirmationModal.ApplyRetrospectively && (
                    <ApplyRetrospectivelyTermAndConditionModal
                        onClose={() => setConfirmationModal(undefined)}
                        organizationId={organizationId}
                        termsAndConditions={termsAndConditions}
                    />
                )}
                {confirmationModal === ConfirmationModal.DisconnectHRIS && (
                    <DisconnectHRISConfirmationModal
                        form={disconnectOrganizationForm}
                        onClose={() => setConfirmationModal(undefined)}
                    />
                )}
                {confirmationModal === ConfirmationModal.GenerateInvoicesModal && (
                    <GenerateOrganizationInvoicesModal
                        organizationId={organizationId}
                        onClose={() => setConfirmationModal(undefined)}
                    />
                )}
                {confirmationModal === ConfirmationModal.DeleteOrganization && (
                    <DangerousConfirmModal
                        onClose={() => setConfirmationModal(undefined)}
                        onConfirm={deleteOrganization}
                        title={`Deleting organization ${organization!.name}`}
                        confirmText={organization.name}
                        confirmButtonLabel='Delete organization'
                        cancelButtonLabel='Cancel'
                        subTitle='This operation will delete organization with all users data, invoices, teams, etc. This operation is irreversible! Please enter organization to delete:'
                        inputLabel='Organization name'
                    />
                )}
                {confirmationModal === ConfirmationModal.UpdateCurrency && (
                    <CurrencyUpdateModal
                        onClose={() => setConfirmationModal(undefined)}
                        organization={organization}
                    />
                )}
                {confirmationModal === ConfirmationModal.DataErasureUpdate && (
                    <DataErasureUpdateModal
                        onClose={() => setConfirmationModal(undefined)}
                        organization={organization}
                    />
                )}
                {confirmationModal === ConfirmationModal.UsersUpload && (
                    <UsersUploadModal
                        onClose={() => setConfirmationModal(undefined)}
                        organization={organization}
                    />
                )}
                {manageModal === ManageModal.Details && (
                    <DetailsUpdateModal
                        onClose={() => setManageModal(undefined)}
                        organization={organization}
                    />
                )}
                {manageModal === ManageModal.FinancialSettings && (
                    <FinancialSettingsUpdateModal
                        onClose={() => setManageModal(undefined)}
                        organization={organization}
                    />
                )}
                {manageModal === ManageModal.Features && (
                    <FeaturesUpdateModal
                        onClose={() => setManageModal(undefined)}
                        organization={organization}
                    />
                )}
                {manageModal === ManageModal.Status && (
                    <StatusUpdateModal
                        onClose={() => setManageModal(undefined)}
                        organization={organization}
                    />
                )}
                {manageModal === ManageModal.Mdm && (
                    <MdmUpdateModal onClose={() => setManageModal(undefined)} organization={organization} />
                )}
                {manageModal === ManageModal.Currency && (
                    <CurrencyUpdateModal
                        onClose={() => setManageModal(undefined)}
                        organization={organization}
                    />
                )}
                {manageModal === ManageModal.DataErasure && (
                    <DataErasureUpdateModal
                        onClose={() => setManageModal(undefined)}
                        organization={organization}
                    />
                )}
                {manageModal === ManageModal.Deel && (
                    <DeelUpdateModal onClose={() => setManageModal(undefined)} organization={organization} />
                )}
                {manageModal === ManageModal.ContractDefaults &&
                    !isEmpty(organization.contractExpirySettings) && (
                        <ContractDefaultsUpdateModal
                            onClose={() => setManageModal(undefined)}
                            organization={organization}
                        />
                    )}
                {manageModal === ManageModal.AddItemsToServicePackage && (
                    <Modals>
                        <DeviceEnrollmentModal
                            organizationId={organizationId}
                            selectedItemsIds={selectedItemsIds}
                            onClose={() => setManageModal(undefined)}
                        />
                    </Modals>
                )}
            </Modals>
        </Page>
    );
};

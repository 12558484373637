import { PageRequest, PageResponse, stringifyUrl, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { EmailLogDto } from './types/EmailLogDto';

class EmailLogService {
    public getEmail = async (emailId: number): Promise<EmailLogDto> => {
        return restClient.getJson<EmailLogDto>(`/api/admin/emails/${emailId}`);
    };

    public listUserEmails = async (userId: UUID, page: PageRequest): Promise<PageResponse<EmailLogDto>> => {
        return restClient.getJson<PageResponse<EmailLogDto>>(
            stringifyUrl({
                url: `/api/admin/emails/users/${userId}`,
                query: {
                    page: page.page,
                    pageSize: page.pageSize,
                },
            }),
        );
    };

    public listOrganizationEmails = async (
        organizationId: UUID,
        page: PageRequest,
    ): Promise<PageResponse<EmailLogDto>> => {
        return restClient.getJson<PageResponse<EmailLogDto>>(
            stringifyUrl({
                url: `/api/admin/emails/organizations/${organizationId}`,
                query: {
                    page: page.page,
                    pageSize: page.pageSize,
                },
            }),
        );
    };
}

export const emailLogService = new EmailLogService();
